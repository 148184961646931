import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const GalleryWrapper = styled.div`
  padding: 20px;
  background: #fff;
  max-width: 800px;
  margin: 0 auto;
`;

const ThumbnailGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 10px;
  margin-bottom: 20px;
`;

const ThumbnailItem = styled.div`
  cursor: pointer;
  border-radius: 4px;
  overflow: hidden;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.05);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    aspect-ratio: 4/3;
  }
`;

const CarouselWrapper = styled.div`
  margin-top: 20px;

  .slider-wrapper {
    max-height: 60vh;
    height: 100% !important;
    }
  
  .carousel .slide {
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .carousel .slide img {
    max-height: 60vh;
    object-fit: contain;
  }
  
  .carousel .control-dots {
    display: none;
  }
  
  .carousel .control-arrow {
    background: rgba(0,0,0,0.2);
    border-radius: 50%;
    margin: auto;
    height: 50px;
    width: 50px;
  }
  
  .carousel .carousel-status {
    right: 20px;
    top: 20px;
    padding: 5px 10px;
    background: rgba(0,0,0,0.5);
  }
  
  .carousel .legend {
    background: rgba(0,0,0,0.7);
    border-radius: 15px 15px 0 0;
    max-width: 80%;
    margin-left: 10%;
    padding: 15px;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImageLoader = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: ${props => props.isLoading ? 'flex' : 'none'};
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const LoaderSpinner = styled.div`
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-top: 3px solid #000;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

const LoaderText = styled.div`
  font-size: 14px;
  color: #333;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
  background: rgba(0,0,0);
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  
  &:hover {
    background: rgba(100,100,100);
  }
`;

const DownloadButton = styled.button`
  position: absolute;
  top: 11px;
  right: 50px;
  z-index: 2;
  background: rgba(0,0,0);
  color: white;
  border: none;
  padding: 5px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${props => props.isDownloading ? 'wait' : 'pointer'};
  text-decoration: none;
  font-size: 14px;
  opacity: ${props => props.isDownloading ? 0.7 : 1};
  
  &:hover {
    background: ${props => props.isDownloading ? 'rgba(0,0,0)' : 'rgba(100,100,100)'};
  }
  
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

const DownloadIcon = styled.span`
  margin-right: 5px;
`;

const DownloadSpinner = styled.div`
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-left-color: #ffffff;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  animation: spin 1s linear infinite;
  margin-right: 8px;
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
  gap: 10px;
  align-items: center;
`;

const PageNumber = styled.button`
  border: ${props => props.active ? '1px solid #999999' : '0'};
  background: ${props => props.active ? '#gggggg' : 'white'};
  color: #333;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  
  &:hover {
    background: ${props => props.active ? '#e6f1ff' : '#f5f5f5'};
  }
  
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

const PageNavButton = styled.button`
  background: black;
  color: #fff;
  border: 0;
  padding: 0 15px;
  height: 36px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  
  &:hover {
    background: #444444;
  }
  
  &:disabled {
    cursor: not-allowed;
    background: #888888;
  }
`;

const PageInfo = styled.div`
  color: #666;
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
`;

const PhotoGallery = ({ photos, title, isLoading }) => {
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginatedPhotos, setPaginatedPhotos] = useState([]);
  const [isDownloading, setIsDownloading] = useState(false);
  const [imageLoadingState, setImageLoadingState] = useState({}); // Keys are indexes, values are boolean
  
  const PHOTOS_PER_PAGE = 12;
  const totalPages = Math.ceil(photos.length / PHOTOS_PER_PAGE);
  
  // Update paginated photos when current page changes or photos array changes
  useEffect(() => {
    const startIndex = (currentPage - 1) * PHOTOS_PER_PAGE;
    const endIndex = startIndex + PHOTOS_PER_PAGE;
    setPaginatedPhotos(photos.slice(startIndex, endIndex));
  }, [currentPage, photos]);
  
  // Reset to page 1 when photos array changes
  useEffect(() => {
    setCurrentPage(1);
  }, [photos]);

  // Reset loading states when viewer is opened or closed
  useEffect(() => {
    if (viewerIsOpen) {
      // Mark all images as loading initially
      const initialState = {};
      photos.forEach((_, index) => {
        initialState[index] = true;
      });
      setImageLoadingState(initialState);
    }
  }, [viewerIsOpen, photos]);

  // Preload adjacent images when currentIndex changes
  useEffect(() => {
    if (viewerIsOpen) {
      // Set current image as loading if not already set
      setImageLoadingState(prev => ({
        ...prev,
        [currentIndex]: prev[currentIndex] !== false
      }));
      
      // Preload the next image
      if (currentIndex < photos.length - 1) {
        const nextImg = new Image();
        nextImg.src = getDisplayUrl(photos[currentIndex + 1]);
      }
      
      // Preload the previous image
      if (currentIndex > 0) {
        const prevImg = new Image();
        prevImg.src = getDisplayUrl(photos[currentIndex - 1]);
      }
    }
  }, [currentIndex, viewerIsOpen, photos]);

  if (isLoading) {
    return (
      <GalleryWrapper>
        <LoadingContainer>
          <p>Loading photos...</p>
        </LoadingContainer>
      </GalleryWrapper>
    );
  }

  // Get the appropriate image URL based on available formats
  const getDisplayUrl = (photo) => {
    // Check if the photo has large format
    if (photo.large && photo.large.url) {
      return photo.large;
    }
    
    // Fallback to the original image if large format doesn't exist
    return photo.url;
  };

  // Get thumbnail URL
  const getThumbnailUrl = (photo) => {
    // Use provided thumbnail if available
    if (photo.thumbnail) {
      return photo.thumbnail;
    }
    
    // Fallback to the original image
    return photo.url;
  };

  // Function to get original/full-size image for download
  const getOriginalUrl = (photo) => {
    return photo.url;
  };

  // Function to get filename from URL
  const getFilenameFromUrl = (url) => {
    if (!url) return 'image.jpg';
    
    // Extract the filename from the URL
    const parts = url.split('/');
    const filename = parts[parts.length - 1];
    // Remove any query parameters
    return filename.split('?')[0];
  };

  const handleImageLoad = (index) => {
    // Mark this specific image as loaded
    setImageLoadingState(prev => ({
      ...prev,
      [index]: false
    }));
  };

  const openCarousel = (index) => {
    // Convert paginated index to global index
    const globalIndex = (currentPage - 1) * PHOTOS_PER_PAGE + index;
    setCurrentIndex(globalIndex);
    setViewerIsOpen(true);
  };

  const closeCarousel = () => {
    setViewerIsOpen(false);
  };
  
  const goToPage = (page) => {
    setCurrentPage(page);
  };

  const handleCarouselChange = (index) => {
    // Update current index
    setCurrentIndex(index);
  };

  const downloadImage = async () => {
    if (isDownloading) return;
    
    setIsDownloading(true);
    const imageUrl = getOriginalUrl(photos[currentIndex]);
    const filename = getFilenameFromUrl(imageUrl);
    
    try {
      // Fetch the image as a blob
      const response = await fetch(imageUrl, {
        method: 'GET',
        headers: {},
        mode: 'cors', // Use CORS mode if the image is from a different domain
      });
      
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      
      const blob = await response.blob();
      
      // Create an object URL for the blob
      const blobUrl = window.URL.createObjectURL(blob);
      
      // Create a temporary anchor element and trigger download
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      
      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
      
      // Set a minimum download time to show the spinner (better UX for fast downloads)
      setTimeout(() => {
        setIsDownloading(false);
      }, 1000);
    } catch (error) {
      console.error('Error downloading image:', error);
      
      // Fallback method if the fetch approach fails
      const link = document.createElement('a');
      link.href = imageUrl;
      link.download = filename;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setIsDownloading(false);
    }
  };
  
  const renderPagination = () => {
    // Don't show pagination if there's only one page
    if (totalPages <= 1) return null;
    
    // Calculate which page numbers to show
    let pageNumbers = [];
    const maxVisiblePages = 5;
    
    if (totalPages <= maxVisiblePages) {
      // Show all pages if there are fewer than maxVisiblePages
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      // Always include first and last page
      pageNumbers.push(1);
      
      // Add ellipsis and pages around current
      if (currentPage > 3) {
        pageNumbers.push('...');
      }
      
      // Pages around current
      const startPage = Math.max(2, currentPage - 1);
      const endPage = Math.min(totalPages - 1, currentPage + 1);
      
      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }
      
      // Add ellipsis if needed
      if (currentPage < totalPages - 2) {
        pageNumbers.push('...');
      }
      
      // Add last page if we're not already there
      if (totalPages > 1) {
        pageNumbers.push(totalPages);
      }
    }
    
    return (
      <Pagination>
        <PageNavButton 
          onClick={() => goToPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </PageNavButton>
        
        {pageNumbers.map((page, index) => {
          if (page === '...') {
            return <span key={`ellipsis-${index}`}>...</span>;
          }
          
          return (
            <PageNumber
              key={page}
              active={currentPage === page}
              onClick={() => goToPage(page)}
            >
              {page}
            </PageNumber>
          );
        })}
        
        <PageNavButton 
          onClick={() => goToPage(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </PageNavButton>
      </Pagination>
    );
  };

  return (
    <GalleryWrapper>
      {!viewerIsOpen ? (
        <>
          <ThumbnailGrid>
            {paginatedPhotos.map((photo, index) => (
              <ThumbnailItem 
                key={photo.id} 
                onClick={() => openCarousel(index)}
              >
                <img 
                  src={getThumbnailUrl(photo)} 
                  alt={photo.caption || `Photo ${index + 1}`} 
                />
              </ThumbnailItem>
            ))}
          </ThumbnailGrid>
          
          {renderPagination()}
          
          <PageInfo>
            Showing {paginatedPhotos.length} of {photos.length} photos
          </PageInfo>
        </>
      ) : (
        <CarouselWrapper>
          <CloseButton onClick={closeCarousel}>×</CloseButton>
          <DownloadButton 
            onClick={downloadImage} 
            disabled={isDownloading}
            isDownloading={isDownloading}
          >
            {isDownloading ? (
              <>
                <DownloadSpinner /> Downloading...
              </>
            ) : (
              <>
                <DownloadIcon>↓</DownloadIcon> Download
              </>
            )}
          </DownloadButton>
          <Carousel
            selectedItem={currentIndex}
            showArrows={true}
            showThumbs={false}
            showStatus={true}
            infiniteLoop={true}
            showIndicators={photos.length > 1}
            useKeyboardArrows={true}
            dynamicHeight={true}
            onChange={handleCarouselChange}
          >
            {photos.map((photo, index) => (
              <ImageContainer key={photo.id}>
                <ImageLoader isLoading={imageLoadingState[index]}>
                  <LoaderSpinner />
                  <LoaderText>Loading image...</LoaderText>
                </ImageLoader>
                <img 
                  src={getDisplayUrl(photo)} 
                  alt={photo.caption || `Photo ${index + 1}`} 
                  onLoad={() => handleImageLoad(index)} 
                  style={{ opacity: imageLoadingState[index] ? 0 : 1, transition: 'opacity 0.3s ease' }}
                />
                {photo.caption && <p className="legend">{photo.caption}</p>}
              </ImageContainer>
            ))}
          </Carousel>
        </CarouselWrapper>
      )}
    </GalleryWrapper>
  );
};

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  font-size: 16px;
  color: #666;
`;

export default PhotoGallery;