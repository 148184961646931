// store/gls-photos/saga.js
import axios from "axios";
import { call, put, takeEvery, select } from 'redux-saga/effects';
import { API, GENERAL, GLS_PHOTOS } from "../../../utils/constants";
import { startLoading, stopLoading } from "../../loading/actions";
import { logout } from "../../user/actions";

function* loadAlbums() {
  try {
    yield put(startLoading());
    const { jwt } = yield select(state => state.user);
    const header = { headers: { Authorization: `Bearer ${jwt}` } };
    const response = yield call(axios, `${API.URL}${API.GLS_ALBUMS_PATH(GENERAL.IS_PREVIEW)}`, header);
    const { data } = response;
    yield put({ type: GLS_PHOTOS.LOAD_ALBUMS_SUCCESS, payload: data });
    yield put(stopLoading());
  } catch (e) {
    const { response } = e;
    if (response && response.status === 403) {
      yield put(logout());
    }
    yield put({ type: GLS_PHOTOS.LOAD_ALBUMS_ERROR });
    yield put(stopLoading(e));
  }
}

function* loadAlbumDetails(action) {
  const { payload: slug } = action;
  try {
    yield put(startLoading());
    const { jwt } = yield select(state => state.user);
    const header = { headers: { Authorization: `Bearer ${jwt}` } };
    const response = yield call(
      axios, 
      `${API.URL}${API.GLS_ALBUM_DETAILS_PATH(slug, GENERAL.IS_PREVIEW)}`, 
      header
    );
    const { data } = response;
    yield put({ type: GLS_PHOTOS.LOAD_ALBUM_DETAILS_SUCCESS, payload: data });
    yield put(stopLoading());
  } catch (e) {
    const { response } = e;
    if (response && response.status === 403) {
      yield put(logout());
    }
    yield put({ type: GLS_PHOTOS.LOAD_ALBUM_DETAILS_ERROR });
    yield put(stopLoading(e));
  }
}

function* initWatchers() {
  yield takeEvery(GLS_PHOTOS.LOAD_ALBUMS, loadAlbums);
  yield takeEvery(GLS_PHOTOS.LOAD_ALBUM_DETAILS, loadAlbumDetails);
}

export default initWatchers;