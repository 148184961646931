import {all} from 'redux-saga/effects';
import createSagaMiddleware from 'redux-saga'
import {configureStore} from '@reduxjs/toolkit';
import reducers from "./reducers";
import company from './company/saga';
import news from './news/saga';
import mediaHub from './media-hub/saga';
import whatsNew from './whatsnew/saga';
import tracking from './tracking/saga';
import sentry from './sentry/saga';
import user from './user/saga';
import sticker from './sticker/saga';
import events from './events/saga';
import glsPhotos from './gls-photo/saga';

const sagaMiddleWare = createSagaMiddleware();

const store = configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(sagaMiddleWare),
})

function* initSagas() {
    yield all([
        user(),
        sentry(),
        tracking(),
        company(),
        whatsNew(),
        mediaHub(),
        news(),
        sticker(),
        events(),
        glsPhotos(),
    ])
}

sagaMiddleWare.run(initSagas)

export default store;
