export const MEDIA_HUB = "MediaHub";
export const IN_A_BOX = "InABox";

export const VIDEO_TYPE = "video";
export const LINK_TYPE = "link";
export const FILE_TYPE = "file";
export const GALLERY_TYPE = "gallery";

export const MEDIA_HUB_CONTENT = [
  {
    id: 0,
    sectionTitle: "GLS 2024",
    path: "/gls/mediahub/gls-2024",
    slug: "gls-2024",
    contents: [
      {
        id: 1,
        title: "GLS 2024 TOWN HALL",
        url: "https://playout.3qsdn.com/9be13018-6a16-4602-a88a-055db38ece00",
        image: "/images/gls/mediahub/gls-2024/gls-townhall.jpg",
      },
    ],
  },
  {
    id: 1,
    sectionTitle: "GLSx 2023",
    path: "/gls/mediahub/glsx-2023",
    slug: "glsx-2023",
    contents: [
      {
        id: 1,
        title: "GLSx TOWN HALL",
        url: "https://playout.3qsdn.com/57abde02-d352-496d-b76c-72719157bdc3",
        image: "/images/gls/mediahub/gls-2023/GLSxTH_PF_Tile-MediaHub.png",
      },
    ],
  },
  {
    id: 2,
    sectionTitle: "GLS22 live",
    path: "/gls/mediahub/gls-2022-live",
    slug: "gls-2022-live",
    contents: [
      {
        id: 1,
        title: "GLS22 FRANCHISE MAIN EVENT",
        url: "https://playout.3qsdn.com/60f28754-4b09-4075-88c2-abb40d7a750d",
        image:
          "/images/gls/mediahub/gls-live-2022/1-1_GLS22_Thumbnails_Franchise.png",
      },
      {
        id: 2,
        title: "GLS22 DERMATOLOGY EVENT",
        url: "https://playout.3qsdn.com/e533a6b1-dc9d-4f7a-872d-1738c99bde96",
        image:
          "/images/gls/mediahub/gls-live-2022/1-2_GLS22_Thumbnails_Dermatology.png",
      },
      {
        id: 3,
        title: "GLS22 RESPIRATORY/GI EVENT",
        url: "https://playout.3qsdn.com/aa9b73c5-bb55-4da5-a8e6-4c97677e3003",
        image:
          "/images/gls/mediahub/gls-live-2022/1-3_GLS22_Thumbnails_Respiratory.png",
      },
    ],
  },
  {
    id: 3,
    sectionTitle: "GLSx 2021",
    path: "/gls/mediahub/glsx-2021",
    slug: "glsx-2021",
    contents: [
      {
        id: 1,
        title: "GLSx TOWN HALL",
        url: "https://playout.3qsdn.com/7f262175-75d9-4b89-adca-65c855e95a4f",
        image: "/images/gls/mediahub/glsx-2021/2_1_GLSx_MediaHub_Thumbnail.png",
      },
      {
        id: 2,
        title: "GLSx AD",
        url: "https://playout.3qsdn.com/b33c5921-d30a-470e-a5b5-4a883a43b68e",
        image: "/images/gls/mediahub/glsx-2021/2_2_GLSx_ad_Q3.png",
      },
      {
        id: 3,
        title: "GLSx RESPIRATORY",
        url: "https://playout.3qsdn.com/0c14061c-c6b0-4da2-9077-52044872fd0e",
        image: "/images/gls/mediahub/glsx-2021/2_3_GLSx_resp_Q3.png",
      },
      {
        id: 4,
        title: "GLSx FRANCHISE MAIN",
        url: "https://playout.3qsdn.com/b5b68026-c101-4c67-afe5-d8e3cb3f9266",
        image: "/images/gls/mediahub/glsx-2021/2_4_GLSx_franchise_Q3.png",
      },
      {
        id: 5,
        title: "PTW TOWN HALL",
        url: "https://playout.3qsdn.com/c863d8b1-3644-45c3-afcb-fd7032752210",
        image: "/images/gls/mediahub/glsx-2021/2_5_GLSx_town_hall.png",
      },
    ],
  },
  {
    id: 4,
    sectionTitle: "GLS21 live",
    path: "/gls/mediahub/gls-2021-live",
    slug: "gls-2021-live",
    contents: [
      {
        id: 1,
        title: "AWARDS CEREMONY",
        url: "https://playout.3qsdn.com/fd62e2d1-20ae-47c1-81a6-d5e6e6a4388e",
        image: "/images/gls/mediahub/gls-live-2021/3-1_GLSx_awards.png",
      },
      {
        id: 2,
        title: "WAYS OF WORKING WORKSHOP",
        url: "https://playout.3qsdn.com/cb207a94-4301-42f6-98a5-ca355d2192e7",
        image: "/images/gls/mediahub/gls-live-2021/3-2_GLSx_waysofworking.png",
      },
      {
        id: 3,
        title: "TYPE 2 WORKSHOP",
        url: "https://playout.3qsdn.com/317bd921-09b6-4eec-8679-eadc8fe10797",
        image: "/images/gls/mediahub/gls-live-2021/3-3_GLSx_type2.png",
      },
      {
        id: 4,
        title: "RESPIRATORY COMPETITION WORKSHOP",
        url: "https://playout.3qsdn.com/9382f560-1465-43ce-bd61-6db3a6084eef",
        image: "/images/gls/mediahub/gls-live-2021/3-4_GLSx_resp.png",
      },
      {
        id: 5,
        title: "AD COMPETITION WORKSHOP",
        url: "https://playout.3qsdn.com/fb63b777-20f3-47bb-88a5-7c22f4f9bf10",
        image: "/images/gls/mediahub/gls-live-2021/3-5_GLSx_ad.png",
      },
      {
        id: 6,
        title: "FRANCHISE MAIN EVENT",
        url: "https://playout.3qsdn.com/11bf6ba5-8783-4cb5-a0f1-50969ead4e80",
        image: "/images/gls/mediahub/gls-live-2021/3-6_GLSx_franchise.png",
      },
    ],
  },
  {
    id: 5,
    sectionTitle: "DUPILUMAB AD",
    path: "/gls/mediahub/dupilumab-ad",
    slug: "dupilumab-ad",
    contents: [
      {
        id: 1,
        title: "DUPILUMAB AD 2021",
        url: "https://playout.3qsdn.com/6bc72ab5-f14d-4c51-9bfc-870d1c562f37",
        image: "/images/gls/mediahub/dupi/4-1_GLS22_TN_Dupi_2021.png",
      },
      {
        id: 2,
        title: "DUPILUMAB AD 2020",
        url: "https://playout.3qsdn.com/22458de2-b459-4b70-b8e7-dc220aea32a8",
        image: "/images/gls/mediahub/dupi/4-2_GLS22_TN_Dupi_2020.png",
      },
    ],
  },
];

const FRANCHISE_INNER = {
  header: "/images/gls/inabox/gls24-innerhub/Header_Franchise.jpg",
  content: [
    {
      id: 1,
      sectionTitle: "GLS24",
      path: "/gls/in-a-box/gls24/2/franchise",
      slug: "franchise",
      contents: [
        {
          id: 1,
          title: "FRANCHISE OPENING RECORDING",
          url: "https://playout.3qsdn.com/6af72a12-06e3-486d-9e5d-d064de51af95",
          image: "/images/gls/inabox/gls24-innerhub/Button_F1.jpg",
          type: VIDEO_TYPE,
        },
        {
          id: 2,
          title: "FRANCHISE OPENING SESSION",
          url: "https://launchpharm.box.com/s/ah42k7f52eywfc5jj7xsq9sa8ezs98wv",
          image: "/images/gls/inabox/gls24-innerhub/Button_F2.jpg",
          type: LINK_TYPE,
        },
        {
          id: 3,
          title: "GLS24 FRANCHISE OPENING VIDEO",
          url: "https://playout.3qsdn.com/7afbadb0-53ba-4c7b-b9e5-871d2f8e708f",
          image: "/images/gls/inabox/gls24-innerhub/Button_F3.jpg",
          type: VIDEO_TYPE,
          bottomSpacer: true
        },
        {
          id: 4,
          title: "PATIENT/KOL SESSION DAY 2 Recording",
          url: "https://playout.3qsdn.com/066bf8fe-35ec-43a3-9f5e-a4afda472f23",
          image: "/images/gls/inabox/gls24-innerhub/Button_P2-1.jpg",
          type: VIDEO_TYPE,
        },
        {
          id: 5,
          title: "PATIENT SESSION DAY 2",
          url: "https://launchpharm.box.com/s/qohuc2ngp8q96i5v2fxgvtc7y6b6epcz",
          image: "/images/gls/inabox/gls24-innerhub/Button_P2-2.jpg",
          type: LINK_TYPE,
        },
        {
          id: 6,
          title: "DAY 2 PATIENT OPENING VIDEO",
          url: "https://playout.3qsdn.com/9eeb1b43-ae3b-4c8a-98fa-1ea3d6dd98cf",
          image: "/images/gls/inabox/gls24-innerhub/Button_P2-3.jpg",
          type: VIDEO_TYPE,
        },
        {
          id: 7,
          title: "AI PATIENT VIDEO",
          url: "https://playout.3qsdn.com/be4d9afa-7c87-4aba-bf22-8ca49dea1780",
          image: "/images/gls/inabox/gls24-innerhub/Button_P2-4.jpg",
          type: VIDEO_TYPE,
          bottomSpacer: true
        },
        {
          id: 8,
          title: "PATIENT/KOL SESSION DAY 3 Recording",
          url: "https://playout.3qsdn.com/f4ec6922-38c1-4669-b931-84de8b5c629f",
          image: "/images/gls/inabox/gls24-innerhub/Button_P3_1.jpg",
          type: VIDEO_TYPE,
        },
        {
          id: 9,
          title: "PATIENT/KOL SESSION DAY 3",
          url: "https://launchpharm.box.com/s/pr4yzz9ih4zkw554jya9is6zmdidujg0",
          image: "/images/gls/inabox/gls24-innerhub/Button_P3-2.jpg",
          type: LINK_TYPE,
          bottomSpacer: true
        },
        {
          id: 10,
          title: "FRANCHISE CLOSING RECORDING",
          url: "https://playout.3qsdn.com/4e8849c0-d12b-4964-bd4a-70b672b66b21",
          image: "/images/gls/inabox/gls24-innerhub/Button_FC1.jpg",
          type: VIDEO_TYPE,
        },
        {
          id: 11,
          title: "FRANCHISE CLOSING SESSSION",
          url: "https://launchpharm.box.com/s/1ey43livabtzu95x5q1384oznsayfx05",
          image: "/images/gls/inabox/gls24-innerhub/Button_FC2.jpg",
          type: LINK_TYPE,
        },
        {
          id: 12,
          title: "GLS HIGHLIGHTS",
          url: "https://playout.3qsdn.com/8cbe2005-e843-4cfe-815d-d9c3d28ce2c8",
          image: "/images/gls/inabox/gls24-innerhub/Button_FC3.jpg",
          type: VIDEO_TYPE,
        },
        {
          id: 13,
          title: "4 DUPIXENT",
          url: "https://playout.3qsdn.com/bdb5f06f-3316-40f1-bd21-5e17548f24bc",
          image: "/images/gls/inabox/gls24-innerhub/Button_FC4.jpg",
          type: VIDEO_TYPE,
        },
      ],
    },
  ],
};

const DERMATOLOGY_INNER = {
  header: "/images/gls/inabox/gls24-innerhub/Header_Dermatology.jpg",
  content: [
    {
      id: 1,
      sectionTitle: "GLS24",
      path: "/gls/in-a-box/gls24/3/dermatology",
      slug: "dermatology",
      contents: [
        {
          id: 1,
          title: "DERMATOLOGY SESSION RECORDING",
          url: "https://playout.3qsdn.com/17fec2b4-756a-4ce0-8ef7-f48363e43187",
          image: "/images/gls/inabox/gls24-innerhub/Button_D1.jpg",
          type: VIDEO_TYPE,
        },
        {
          id: 2,
          title: "DERMATOLOGY SESSION",
          url: "https://launchpharm.box.com/s/bi04r8nqwmnsy20t47h6sq2pj8t88km8",
          image: "/images/gls/inabox/gls24-innerhub/Button_D2.jpg",
          type: LINK_TYPE,
        },
        {
          id: 3,
          title: "DERM OPENING SIZZLE",
          url: "https://playout.3qsdn.com/a3568449-7d56-4f24-a705-b22c301c3e7e",
          image: "/images/gls/inabox/gls24-innerhub/Button_D3.jpg",
          type: VIDEO_TYPE,
        },
        {
          id: 4,
          title: "DERM 2023 ACHIEVEMENTS COCKPIT VIEW",
          url: "https://playout.3qsdn.com/a3390a27-c9b7-4a4b-b68e-4c870de07dc2",
          image: "/images/gls/inabox/gls24-innerhub/Button_D4.jpg",
          type: VIDEO_TYPE,
        },
        {
          id: 5,
          title: "SANOFI PATIENT - HAND AND FOOT",
          url: "https://playout.3qsdn.com/65c5c8cc-8453-4b7d-a078-19d2db638273",
          image: "/images/gls/inabox/gls24-innerhub/Button_D5.jpg",
          type: VIDEO_TYPE,
        },
        {
          id: 6,
          title: "1+1= 3",
          url: "https://playout.3qsdn.com/4a638d0c-3b6a-4aa4-9f6b-167c2f3bac4c",
          image: "/images/gls/inabox/gls24-innerhub/Button_D6.jpg",
          type: VIDEO_TYPE,
        },
        {
          id: 7,
          title: "FIELD PERSPECTIVE",
          url: "https://playout.3qsdn.com/f398beab-8d49-4cb4-aaf6-0ee5b715b21e",
          image: "/images/gls/inabox/gls24-innerhub/Button_D7.jpg",
          type: VIDEO_TYPE,
        },
      ],
    },
  ],
};

const COPD_INNER = {
  header: "/images/gls/inabox/gls24-innerhub/Header_COPD.jpg",
  content: [
    {
      id: 1,
      sectionTitle: "GLS24",
      path: "/gls/in-a-box/gls24/4/copd",
      slug: "copd",
      contents: [
        {
          id: 1,
          title: "COPD SESSION RECORDING",
          url: "https://playout.3qsdn.com/b05bcc88-9c8b-4e08-bab5-a9e293f628c7",
          image: "/images/gls/inabox/gls24-innerhub/Button_C1.jpg",
          type: VIDEO_TYPE,
        },
        {
          id: 2,
          title: "COPD SESSION",
          url: "https://launchpharm.box.com/s/ku8v3pqr72mz4ubnm3fmxerq1tkgs8dz",
          image: "/images/gls/inabox/gls24-innerhub/Button_C2.jpg",
          type: LINK_TYPE,
        },
        {
          id: 3,
          title: "OPENING IMPACT VIDEO",
          url: "https://playout.3qsdn.com/5f78ad4e-0e34-4470-81c7-f71e322821ab",
          image: "/images/gls/inabox/gls24-innerhub/Button_C3.jpg",
          type: VIDEO_TYPE,
        },
        {
          id: 4,
          title: "THE TIME IS NOW",
          url: "https://playout.3qsdn.com/0e63ba91-b5b4-4dd1-98bc-291d153ce39c",
          image: "/images/gls/inabox/gls24-innerhub/Button_C4.jpg",
          type: VIDEO_TYPE,
        },
        {
          id: 5,
          title: "COPD CAMPAIGN",
          url: "https://playout.3qsdn.com/1271d85c-f176-4935-907c-58c71bf1753e",
          image: "/images/gls/inabox/gls24-innerhub/Button_C5.jpg",
          type: VIDEO_TYPE,
        },
        {
          id: 6,
          title: "COPD COUNTRY VIDEO",
          url: "https://playout.3qsdn.com/80c9a44d-b26f-4e1b-8f85-20d858a7306a",
          image: "/images/gls/inabox/gls24-innerhub/Button_C6.jpg",
          type: VIDEO_TYPE,
        },
        {
          id: 7,
          title: "HISTORY OF COPD",
          url: "https://playout.3qsdn.com/03496837-02eb-4327-b1b9-d2fa7d0b9e5a",
          image: "/images/gls/inabox/gls24-innerhub/Button_C7.jpg",
          type: VIDEO_TYPE,
        },
      ],
    },
  ],
};

const ASTHMA_INNER = {
  header: "/images/gls/inabox/gls24-innerhub/Header_Asthma.jpg",
  content: [
    {
      id: 1,
      sectionTitle: "GLS24",
      path: "/gls/in-a-box/gls24/5/asthma",
      slug: "asthma",
      contents: [
        {
          id: 1,
          title: "ASTHMA SESSION RECORDING",
          url: "https://playout.3qsdn.com/3cceead4-9a39-4c6e-baa5-6b0056c46094",
          image: "/images/gls/inabox/gls24-innerhub/Button_A1.jpg",
          type: VIDEO_TYPE,
        },
        {
          id: 2,
          title: "ASTHMA SESSION PPT",
          url: "https://launchpharm.box.com/s/ccmb3q5l064qhgxtx3aw3gnc2k2y2j8m",
          image: "/images/gls/inabox/gls24-innerhub/Button_A2.jpg",
          type: LINK_TYPE,
        },
        {
          id: 3,
          title: "TORCH RUN OPENING",
          url: "https://playout.3qsdn.com/8507695b-45a7-4471-8651-23d159fd9dcf",
          image: "/images/gls/inabox/gls24-innerhub/Button_A3.jpg",
          type: VIDEO_TYPE,
        },
        {
          id: 4,
          title: "ASTHMA OPENING VIDEO ",
          url: "https://playout.3qsdn.com/d256e057-8336-46d9-8ada-8b0279fb513c",
          image: "/images/gls/inabox/gls24-innerhub/Button_A4.jpg",
          type: VIDEO_TYPE,
        },
        {
          id: 5,
          title: "EVENT 1: WIN Priority Patients",
          url: "https://playout.3qsdn.com/d6a3d3fe-6db8-47e2-b721-bd855aeeb9f1",
          image: "/images/gls/inabox/gls24-innerhub/Button_A5.jpg",
          type: VIDEO_TYPE,
        },
        {
          id: 6,
          title: "EVENT 2: LEAD the Breakthrough in Remission",
          url: "https://playout.3qsdn.com/0e793de9-d45d-4443-95e2-fe441b11ecc8",
          image: "/images/gls/inabox/gls24-innerhub/Button_A6.jpg",
          type: VIDEO_TYPE,
        },
        {
          id: 7,
          title: "EVENT 3: Elevate LF and FeNO",
          url: "https://playout.3qsdn.com/e2af9125-f644-49d3-88a1-411c30d5145b",
          image: "/images/gls/inabox/gls24-innerhub/Button_A7.jpg",
          type: VIDEO_TYPE,
        },
        {
          id: 8,
          title: "EVENT 4: Omnichannel Execution",
          url: "https://playout.3qsdn.com/e243ea2f-8f9c-4622-99d6-7ede18eece02",
          image: "/images/gls/inabox/gls24-innerhub/Button_A8.jpg",
          type: VIDEO_TYPE,
        },
        {
          id: 9,
          title: "SPAIN VIDEO",
          url: "https://playout.3qsdn.com/56615497-22b0-493e-98c9-a881722209e7",
          image: "/images/gls/inabox/gls24-innerhub/Button_A9.jpg",
          type: VIDEO_TYPE,
        },
        {
          id: 10,
          title: "GO 4  GOLD E-Booklet",
          url: "https://launchpharm.box.com/s/15tn9ivd0vu4ztkdnbznzhe4ihqioj1l",
          image: "/images/gls/inabox/gls24-innerhub/Button_A10.jpg",
          type: LINK_TYPE,
        },
        {
          id: 11,
          title: "ASTHMA CLOSING VIDEO",
          url: "https://playout.3qsdn.com/27cfb297-0f91-405d-a7ef-6217c7014221",
          image: "/images/gls/inabox/gls24-innerhub/Button_A11.jpg",
          type: VIDEO_TYPE,
        },
      ],
    },
  ],
};

const CRSWNP_INNER = {
  header: "/images/gls/inabox/gls24-innerhub/Header_CRSwNP_EoE.jpg",
  content: [
    {
      id: 1,
      sectionTitle: "GLS24",
      path: "/gls/in-a-box/gls24/6/crswnpeoe",
      slug: "crswnpeoe",
      contents: [
        {
          id: 1,
          title: "CRSwNP Recording",
          url: "https://playout.3qsdn.com/d0e38bd6-3f24-463a-bb07-10474c451073",
          image: "/images/gls/inabox/gls24-innerhub/Button_N1.jpg",
          type: VIDEO_TYPE,
        },
        {
          id: 2,
          title: "CRSwNP PPT",
          url: "https://launchpharm.box.com/s/mmdnzef0k54uaz4xwh8q80ojyf0vyg01",
          image: "/images/gls/inabox/gls24-innerhub/Button_N3.jpg",
          type: LINK_TYPE,
        },
        {
          id: 3,
          title: "NEVER UNDERESTIMATE",
          url: "https://playout.3qsdn.com/dcaf9b29-92d7-4af5-a6a0-7947d4f4a737",
          image: "/images/gls/inabox/gls24-innerhub/Button_N4.jpg",
          type: VIDEO_TYPE,
        },
        {
          id: 4,
          title: "CAMPAIGN PREVIEW SIZZLE",
          url: "https://playout.3qsdn.com/556b3257-4df2-4fff-a719-708d57610d84",
          image: "/images/gls/inabox/gls24-innerhub/Button_N5.jpg",
          type: VIDEO_TYPE,
        },
        {
          id: 5,
          title: "DuTell VIDEO",
          url: "https://playout.3qsdn.com/5314e6fb-b049-43fd-a207-9625ac0b62d2",
          image: "/images/gls/inabox/gls24-innerhub/Button_N6.jpg",
          type: VIDEO_TYPE,
        },
      ],
    },
  ],
};

const Q2_INNER = {
  header: "/images/gls/inabox/q2-update/C.jpg",
  content: [
    {
      id: 1,
      sectionTitle: "GLS24",
      path: "/gls/in-a-box/gls24/0/summer-update",
      slug: "summer-update",
      contents: [
        {
          id: 1,
          title: "GLS24 SUMMER UPDATE",
          url: "https://playout.3qsdn.com/9c4abdab-367e-45bc-901c-952583c35e87",
          image: "/images/gls/inabox/q2-update/D.jpg",
          type: VIDEO_TYPE,
          bottomSpacer: true
        },
        {
          id: 2,
          title: "GLS24 SUMMER UPDATE: OPENING",
          url: "https://playout.3qsdn.com/ec42317c-af9c-4115-b3b7-ceecc8605364",
          image: "/images/gls/inabox/q2-update/E.jpg",
          type: VIDEO_TYPE,
        },
        {
          id: 3,
          title: "AFFILIATE SPOTLIGHT CASE: UK & Ireland",
          url: "https://playout.3qsdn.com/9a652905-5b50-475c-970f-51f26d5d5e02",
          image: "/images/gls/inabox/q2-update/F.jpg",
          type: VIDEO_TYPE,
        },
        {
          id: 4,
          title: "AFFILIATE SPOTLIGHT CASE: Germany",
          url: "https://playout.3qsdn.com/f427d32f-b3cd-4cb7-a074-ceda1cb30344",
          image: "/images/gls/inabox/q2-update/G.jpg",
          type: VIDEO_TYPE,
        },
        {
          id: 5,
          title: "AFFILIATE SPOTLIGHT CASE: Italy",
          url: "https://playout.3qsdn.com/5a71f166-db55-491c-a36d-6df1fdaf5ff0",
          image: "/images/gls/inabox/q2-update/H.jpg",
          type: VIDEO_TYPE,
        },
        {
          id: 6,
          title: "AFFILIATE SPOTLIGHT CASE: Brazil",
          url: "https://playout.3qsdn.com/159dcaa8-58cf-4bd8-81be-096e0b94999b",
          image: "/images/gls/inabox/q2-update/I.jpg",
          type: VIDEO_TYPE,
        },
        {
          id: 7,
          title: "GLS24 SUMMER UPDATE: CLOSING",
          url: "https://playout.3qsdn.com/20a6e102-5087-49f9-be63-dc3b569e6a97",
          image: "/images/gls/inabox/q2-update/J.jpg",
          type: VIDEO_TYPE,
          bottomSpacer: true
        },
        {
          id: 8,
          title: "AFFILIATE SPOTLIGHT CASE: UK & Ireland  - FULL INTERVIEW",
          url: "https://playout.3qsdn.com/c81c7de7-6a30-4017-9723-4cc64ffe108f",
          image: "/images/gls/inabox/q2-update/K_2.jpg",
          type: VIDEO_TYPE,
        },
        {
          id: 9,
          title: "AFFILIATE SPOTLIGHT CASE: Germany - FULL INTERVIEW",
          url: "https://playout.3qsdn.com/afc2aba6-2f85-4f10-b0cb-d361db6d78b9",
          image: "/images/gls/inabox/q2-update/L_2.jpg",
          type: VIDEO_TYPE,
        },
        {
          id: 10,
          title: "AFFILIATE SPOTLIGHT CASE: Italy  - FULL INTERVIEW",
          url: "https://playout.3qsdn.com/699238d7-edbd-47c1-8441-9a90edfeb4bc",
          image: "/images/gls/inabox/q2-update/M_2.jpg",
          type: VIDEO_TYPE,
        },
        {
          id: 11,
          title: "AFFILIATE SPOTLIGHT CASE: Brazil - FULL INTERVIEW",
          url: "https://playout.3qsdn.com/f7788573-01b7-4b13-acdc-3d2c37490f46",
          image: "/images/gls/inabox/q2-update/N_2.jpg",
          type: VIDEO_TYPE,
        },
      ],
    },
  ],
};

const FRANCHISE_INNER25 = {
  header: "/images/gls/inabox25/02 GIAB Header/Header Franchise.jpg",
  content: [
    {
      id: 2,
      sectionTitle: "2025",
      path: "/gls/in-a-box/gls25/2/franchise",
      slug: "franchise",
      contents: [
        {
          id: 1,
          title: "FRANCHISE OPENING",
          url: "https://playout.3qsdn.com/de7a5687-9c50-4a38-8204-8c50d1082419",
          image: "/images/gls/inabox25/03 GIAB Buttons/1 F Franchise/GIAB PF_F1.jpg",
          type: VIDEO_TYPE,
        },
        {
          id: 2,
          title: "FRANCHISE OPENING SESSION",
          url: "https://launchpharm.box.com/s/gzpmvpxlbpd9m3j6ltb0jp149ff1cmil",
          image: "/images/gls/inabox25/03 GIAB Buttons/1 F Franchise/GIAB PF_F2.jpg",
          type: LINK_TYPE,
        },
        {
          id: 3,
          title: "GLSLIVE25 OPENING VIDEO - PART 1",
          url: "https://playout.3qsdn.com/c130d632-1479-46d3-a720-26bab119581c",
          image: "/images/gls/inabox25/03 GIAB Buttons/1 F Franchise/GIAB PF_F3.jpg",
          type: VIDEO_TYPE,
        },
        {
          id: 4,
          title: "GLSLIVE25 OPENING VIDEO - PART 2",
          url: "https://playout.3qsdn.com/3b73f70d-ed36-4056-8123-418d885fae4d",
          image: "/images/gls/inabox25/03 GIAB Buttons/1 F Franchise/GIAB PF_F4.jpg",
          type: VIDEO_TYPE,
          bottomSpacer: true
        },
        {
          id: 5,
          title: "KOL SESSION DAY 2",
          url: "https://playout.3qsdn.com/49216633-827a-416e-a38e-7ac5f1cf7312",
          image: "/images/gls/inabox25/03 GIAB Buttons/1 F Franchise/GIAB PF_F5.jpg",
          type: VIDEO_TYPE,
        },
        {
          id: 6,
          title: "KOL SESSION DAY 2 ",
          url: "https://launchpharm.box.com/s/3lexfvy10juhh0e3ql8x5ayz7sa3stl0",
          image: "/images/gls/inabox25/03 GIAB Buttons/1 F Franchise/GIAB PF_F6.jpg",
          type: LINK_TYPE,
        },
        {
          id: 7,
          title: "YOUR VOICE VIDEO ",
          url: "https://playout.3qsdn.com/a01e194e-87e5-4ae3-9752-273cbdc0aea4",
          image: "/images/gls/inabox25/03 GIAB Buttons/1 F Franchise/GIAB PF_F7.jpg",
          type: VIDEO_TYPE,
        },
        {
          id: 8,
          title: "PATIENT NUMBERS ",
          url: "https://playout.3qsdn.com/a0fa2361-636c-41f2-9536-5cc910d0bf58",
          image: "/images/gls/inabox25/03 GIAB Buttons/1 F Franchise/GIAB PF_F8.jpg",
          type: VIDEO_TYPE,
          bottomSpacer: true
        },
        {
          id: 9,
          title: "INVESTOR SESSION DAY 3",
          url: "https://playout.3qsdn.com/0d706f4a-dcd8-4219-98a6-3f57077020f2",
          image: "/images/gls/inabox25/03 GIAB Buttons/1 F Franchise/GIAB PF_F9.jpg",
          type: VIDEO_TYPE,
        },
        {
          id: 10,
          title: "INVESTOR SESSINON DAY 3",
          url: "https://launchpharm.box.com/s/csc7jnd5e2grzy84u7pu5ziuv8mjdhkf",
          image: "/images/gls/inabox25/03 GIAB Buttons/1 F Franchise/GIAB PF_F10.jpg",
          type: LINK_TYPE,
        },
        {
          id: 11,
          title: "1 MILLION FIRSTS",
          url: "https://playout.3qsdn.com/231eeda7-d54a-496c-a232-a1f81f74a9c9",
          image: "/images/gls/inabox25/03 GIAB Buttons/1 F Franchise/GIAB PF_F11.jpg",
          type: VIDEO_TYPE,
          bottomSpacer: true
        },
        {
          id: 12,
          title: "MASTERCLASS: Women in Leadership",
          url: "https://launchpharm.box.com/s/bl097ax0n3u2x3p0aa4qh0nhnwwg1i8g",
          image: "/images/gls/inabox25/03 GIAB Buttons/1 F Franchise/GIAB PF_F12.jpg",
          type: LINK_TYPE,
        },
        {
          id: 13,
          title: "MASTERCLASS: Enterprise Leadership",
          url: "https://launchpharm.box.com/s/6mufzrknp0qsoicutcplmy9o80047qp1",
          image: "/images/gls/inabox25/03 GIAB Buttons/1 F Franchise/GIAB PF_F13.jpg",
          type: LINK_TYPE,
        },
        {
          id: 14,
          title: "MASTERCLASS: Unleashing the Power of People Through Focused Development",
          url: "https://launchpharm.box.com/s/lklhnwxtqqope00jnf49x0yyxm8g250z",
          image: "/images/gls/inabox25/03 GIAB Buttons/1 F Franchise/GIAB PF_F14.jpg",
          type: LINK_TYPE,
          bottomSpacer: true
        },
        {
          id: 15,
          title: "FRANCHISE CLOSING",
          url: "https://playout.3qsdn.com/49069133-8f43-4646-89ad-b48def294a49",
          image: "/images/gls/inabox25/03 GIAB Buttons/1 F Franchise/GIAB PF_F15.jpg",
          type: VIDEO_TYPE,
        },
        {
          id: 16,
          title: "FRANCHISE CLOSING SESSION",
          url: "https://launchpharm.box.com/s/02g490v5yoyhjnhf6ktfg2pzn9jvy6lp",
          image: "/images/gls/inabox25/03 GIAB Buttons/1 F Franchise/GIAB PF_F16.jpg",
          type: LINK_TYPE,
        },
        {
          id: 17,
          title: "GLSLIVE25 HIGHLIGHTS",
          url: "https://playout.3qsdn.com/a7990163-db5b-4489-81a7-7bad698e0ef9",
          image: "/images/gls/inabox25/03 GIAB Buttons/1 F Franchise/GIAB PF_F17.jpg",
          type: VIDEO_TYPE,
          bottomSpacer: true
        },
        {
          id: 18,
          title: "BEST PRACTICE POSTERS",
          url: "https://launchpharm.box.com/s/1m7tmvewvpkm5kbievvc6tj48kt0m54m",
          image: "/images/gls/inabox25/03 GIAB Buttons/1 F Franchise/GIAB PF_F19.jpg",
          type: LINK_TYPE,
          bottomSpacer: true
        },
        {
          id: 19,
          title: "AWARD NIGHT",
          url: "https://playout.3qsdn.com/7e444b94-317c-498a-a0f2-cdab6640dc2c",
          image: "/images/gls/inabox25/03 GIAB Buttons/1 F Franchise/GIAB PF_F18_v2.jpg",
          type: VIDEO_TYPE,
        },
      ],
    },
  ],
};

const DERMATOLOGY_INNER25 = {
  header: "/images/gls/inabox25/02 GIAB Header/Header Dermatology.jpg",
  content: [
    {
      id: 3,
      sectionTitle: "2025",
      path: "/gls/in-a-box/gls25/3/dermatology",
      slug: "dermatology",
      contents: [
        {
          id: 1,
          title: "DERMATOLOGY SESSION",
          url: "https://playout.3qsdn.com/0c97ce44-d4cb-401e-9df3-459a25a38c8e",
          image: "/images/gls/inabox25/03 GIAB Buttons/2 D Dermatology/GIAB PF_D1.jpg",
          type: VIDEO_TYPE,
        },
        {
          id: 2,
          title: "DERMATOLOGY SESSION",
          url: "https://launchpharm.box.com/s/vj59avsqixesbuv2cdqqr6bph7q1gstb",
          image: "/images/gls/inabox25/03 GIAB Buttons/2 D Dermatology/GIAB PF_D2.jpg",
          type: LINK_TYPE,
        },
        {
          id: 3,
          title: "MASTERS OF THE IMPOSSIBLE - KOL",
          url: "https://launchpharm.box.com/s/ncc3z3wkda2njbus0uwrz5l3h82z1d49",
          image: "/images/gls/inabox25/03 GIAB Buttons/2 D Dermatology/GIAB PF_D3.jpg",
          type: LINK_TYPE,
        },
        {
          id: 4,
          title: "CAMPAIGN",
          url: "https://launchpharm.box.com/s/ky1kpvd3r412ylxc89rdpxjefre0lnho",
          image: "/images/gls/inabox25/03 GIAB Buttons/2 D Dermatology/GIAB PF_D4.jpg",
          type: LINK_TYPE,
        },
        {
          id: 5,
          title: "MASTERSTRUCK",
          url: "https://playout.3qsdn.com/d3bab09f-e0a0-4257-b868-60738fe8ec2b",
          image: "/images/gls/inabox25/03 GIAB Buttons/2 D Dermatology/GIAB PF_D5.jpg",
          type: VIDEO_TYPE,
        },
        {
          id: 6,
          title: "CAMPAIGN REVEAL VIDEO",
          url: "https://playout.3qsdn.com/4892d13b-c2b3-48aa-8d07-3404d611e5f3",
          image: "/images/gls/inabox25/03 GIAB Buttons/2 D Dermatology/GIAB PF_D6.jpg",
          type: VIDEO_TYPE,
        },
        {
          id: 7,
          title: "INDICATION DEEP DIVE: DERMATOLOGY - Unlocking Miracles and Growth",
          url: "https://launchpharm.box.com/s/brsdirmieetnh20jl8v1m325l0k6cagk",
          image: "/images/gls/inabox25/03 GIAB Buttons/2 D Dermatology/GIAB PF_D7.jpg",
          type: LINK_TYPE,
        },
      ],
    },
  ],
};

const RESPIRATORY_INNER25 = {
  header: "/images/gls/inabox25/02 GIAB Header/Header RESP.jpg",
  content: [
    {
      id: 4,
      sectionTitle: "2025",
      path: "/gls/in-a-box/gls25/4/respiratory",
      slug: "respiratory",
      contents: [
        {
          id: 1,
          title: "RESPIRATORY SESSION",
          url: "https://playout.3qsdn.com/3453bb57-9861-4f28-8b1c-1f3e4975caec",
          image: "/images/gls/inabox25/03 GIAB Buttons/3 R Respiratory/GIAB PF_R1.jpg",
          type: VIDEO_TYPE,
        },
        {
          id: 2,
          title: "RESPIRATORY SESSION",
          url: "https://launchpharm.box.com/s/s1uwxiqpoeeuzij89az6z6z2y7pf2823",
          image: "/images/gls/inabox25/03 GIAB Buttons/3 R Respiratory/GIAB PF_R2.jpg",
          type: LINK_TYPE,
        },
        {
          id: 3,
          title: "RESPIRATORY OPENING ExtraordinAIRy",
          url: "https://playout.3qsdn.com/3d5138d0-b565-4c6f-a1a1-cc3094947c24",
          image: "/images/gls/inabox25/03 GIAB Buttons/3 R Respiratory/GIAB PF_R3.jpg",
          type: VIDEO_TYPE,
        },
        {
          id: 4,
          title: "PATIENT VIDEO",
          url: "https://playout.3qsdn.com/f721a743-7679-48f5-a366-a75c0344517b",
          image: "/images/gls/inabox25/03 GIAB Buttons/3 R Respiratory/GIAB PF_R4.jpg",
          type: VIDEO_TYPE,
        },
        {
          id: 5,
          title: "ASTHMA VIDEO",
          url: "https://playout.3qsdn.com/ba60bd7a-8b71-4ca0-874b-308fd867d445",
          image: "/images/gls/inabox25/03 GIAB Buttons/3 R Respiratory/GIAB PF_R5.jpg",
          type: VIDEO_TYPE,
        },
        {
          id: 6,
          title: "COPD VIDEO",
          url: "https://playout.3qsdn.com/25860777-9d87-4c40-9010-dd26342fbc59",
          image: "/images/gls/inabox25/03 GIAB Buttons/3 R Respiratory/GIAB PF_R6.jpg",
          type: VIDEO_TYPE,
        },
        {
          id: 7,
          title: "PULMONOLOGIST VIDEO",
          url: "https://playout.3qsdn.com/548cf41a-b181-4d23-ab67-244aaf751496",
          image: "/images/gls/inabox25/03 GIAB Buttons/3 R Respiratory/GIAB PF_R7.jpg",
          type: VIDEO_TYPE,
          bottomSpacer: true
        },
        {
          id: 8,
          title: "INDICATION DEEP DIVE: ASTHMA/CRSwNP - Unified Airways",
          url: "https://launchpharm.box.com/s/kxp0m7lmxcewxjsyi2w25n56p0bc4196",
          image: "/images/gls/inabox25/03 GIAB Buttons/3 R Respiratory/GIAB PF_R8.jpg",
          type: LINK_TYPE,
        },
        {
          id: 9,
          title: "INDICATION DEEP DIVE: Asthma - Welcome on board your Respiratory ExtraordinAlRy Travel",
          url: "https://launchpharm.box.com/s/vb7uovjmq8xitfgqhyh1h74d0fw921ae",
          image: "/images/gls/inabox25/03 GIAB Buttons/3 R Respiratory/GIAB PF_R9_v2.jpg",
          type: LINK_TYPE,
        },
        {
          id: 10,
          title: "INDICATION DEEP DIVE: COPD - Trailblazing COPD Market Access",
          url: "https://launchpharm.box.com/s/3539s94qq8w9kg9ughorvj6m17z1xbox",
          image: "/images/gls/inabox25/03 GIAB Buttons/3 R Respiratory/GIAB PF_R10.jpg",
          type: LINK_TYPE,
        },
      ],
    },
  ],
};

const RHINO_GASTRO_INNER25 = {
  header: "/images/gls/inabox25/02 GIAB Header/Header RHINO GASTRO.jpg",
  content: [
    {
      id: 5,
      sectionTitle: "2025",
      path: "/gls/in-a-box/gls25/5/rhino-gastro",
      slug: "rhino-gastro",
      contents: [
        {
          id: 1,
          title: "RHINO GASTRO SESSION",
          url: "https://playout.3qsdn.com/927b727f-bab6-4e28-97fa-bf0c2b40fdae",
          image: "/images/gls/inabox25/03 GIAB Buttons/4 RG Rhino Gastro/GIAB PF_RG1.jpg",
          type: VIDEO_TYPE,
        },
        {
          id: 2,
          title: "RHINO GASTRO SESSION - EoE AND CLOSING",
          url: "https://launchpharm.box.com/s/8c38dvfxl4u4htrgsz7rl9bqpyl1nli5",
          image: "/images/gls/inabox25/03 GIAB Buttons/4 RG Rhino Gastro/GIAB PF_RG2.jpg",
          type: LINK_TYPE,
        },
        {
          id: 3,
          title: "RHINO GASTRO SESSION - CRSwNP",
          url: "https://launchpharm.box.com/s/snrf4fnjg4ch6pdl8nvydadexaq4r72d",
          image: "/images/gls/inabox25/03 GIAB Buttons/4 RG Rhino Gastro/GIAB PF_RG3.jpg",
          type: LINK_TYPE,
        },
        {
          id: 4,
          title: "RHINO GASTRO OPENING",
          url: "https://playout.3qsdn.com/66f13491-d9af-4299-a161-7318afeed83a",
          image: "/images/gls/inabox25/03 GIAB Buttons/4 RG Rhino Gastro/GIAB PF_RG4.jpg",
          type: VIDEO_TYPE,
        },
        {
          id: 5,
          title: "JOY IN UNEXPECTED MOMENTS",
          url: "https://playout.3qsdn.com/34dc3f1d-f1d1-4de6-af1e-c4e236d1f8ba",
          image: "/images/gls/inabox25/03 GIAB Buttons/4 RG Rhino Gastro/GIAB PF_RG5.jpg",
          type: VIDEO_TYPE,
        },
        {
          id: 6,
          title: "CRSwNP PATIENT LEARNING JOURNEY",
          url: "https://playout.3qsdn.com/701d9142-1628-4e7e-adfe-f865cd67269f",
          image: "/images/gls/inabox25/03 GIAB Buttons/4 RG Rhino Gastro/GIAB PF_RG6.jpg",
          type: VIDEO_TYPE,
        },
        {
          id: 7,
          title: "LAUNCH VIDEO",
          url: "https://playout.3qsdn.com/10563bf4-78cf-4485-9d85-d04ba19c576d",
          image: "/images/gls/inabox25/03 GIAB Buttons/4 RG Rhino Gastro/GIAB PF_RG7.jpg",
          type: VIDEO_TYPE,
        },
        {
          id: 8,
          title: "CRSwNP STARBUCKS",
          url: "https://playout.3qsdn.com/07e3fe1d-ae4b-4f3d-a642-d5bc0750050e",
          image: "/images/gls/inabox25/03 GIAB Buttons/4 RG Rhino Gastro/GIAB PF_RG8.jpg",
          type: VIDEO_TYPE,
        },
        {
          id: 9,
          title: "GASTRO FESTIVALS",
          url: "https://playout.3qsdn.com/aea93ca0-dfe8-4a1e-83eb-1ffa0fd8ec2c",
          image: "/images/gls/inabox25/03 GIAB Buttons/4 RG Rhino Gastro/GIAB PF_RG9.jpg",
          type: VIDEO_TYPE,
        },
        {
          id: 10,
          title: "PHOTO SLIDESHOW COUNTRIES",
          url: "https://playout.3qsdn.com/e40276fb-1888-4c9a-ae26-74f49e99b1e3",
          image: "/images/gls/inabox25/03 GIAB Buttons/4 RG Rhino Gastro/GIAB PF_RG10.jpg",
          type: VIDEO_TYPE,
        },
        {
          id: 11,
          title: "PHOTO SLIDESHOW",
          url: "https://playout.3qsdn.com/321961a9-9ee1-49d1-a20e-6ea2b7f16eae",
          image: "/images/gls/inabox25/03 GIAB Buttons/4 RG Rhino Gastro/GIAB PF_RG16.jpg",
          type: VIDEO_TYPE,
        },
        {
          id: 12,
          title: "FRENCH PATIENT ACTIVATION",
          url: "https://playout.3qsdn.com/9606afbb-a59d-479c-b1b1-3f2836c4667c",
          image: "/images/gls/inabox25/03 GIAB Buttons/4 RG Rhino Gastro/GIAB PF_RG11.jpg",
          type: VIDEO_TYPE,
          bottomSpacer: true
        },
        {
          id: 13,
          title: "INDICATION DEEP DIVE: CRSwNP/ASTHMA - Unified Airways",
          url: "https://launchpharm.box.com/s/kxp0m7lmxcewxjsyi2w25n56p0bc4196",
          image: "/images/gls/inabox25/03 GIAB Buttons/4 RG Rhino Gastro/GIAB PF_RG12.jpg",
          type: LINK_TYPE,
        },
        {
          id: 14,
          title: "INDICATION DEEP DIVE: CRSwNP - Amplifying Momentum",
          url: "https://launchpharm.box.com/s/c8erpvxjq7pi57epajystpr66ilz3uht",
          image: "/images/gls/inabox25/03 GIAB Buttons/4 RG Rhino Gastro/GIAB PF_RG13.jpg",
          type: LINK_TYPE,
        },
        {
          id: 15,
          title: "INDICATION DEEP DIVE: EoE - Advancing Diagnostic Precision",
          url: "https://launchpharm.box.com/s/v4l0dir2s21gpwi7ioc5t60ilpe9lwko",
          image: "/images/gls/inabox25/03 GIAB Buttons/4 RG Rhino Gastro/GIAB PF_RG14.jpg",
          type: LINK_TYPE,
        },
        {
          id: 16,
          title: "INDICATION DEEP DIVE: EoE - ROCKET VIDEO",
          url: "https://playout.3qsdn.com/2af6060d-d2ca-4314-8456-76f31906b513",
          image: "/images/gls/inabox25/03 GIAB Buttons/4 RG Rhino Gastro/GIAB PF_RG15.jpg",
          type: VIDEO_TYPE,
        }
      ]
    }
  ]
};

const ITEPEKIMAB_INNER25 = {
  header: "/images/gls/inabox25/02 GIAB Header/Header ITEP.jpg",
  content: [
    {
      id: 6,
      sectionTitle: "2025",
      path: "/gls/in-a-box/gls25/6/itepekimab",
      slug: "itepekimab",
      contents: [
        {
          id: 1,
          title: "ITEPEKIMAB SESSION",
          url: "https://playout.3qsdn.com/2755923f-923a-4a39-b6b1-93a1429d6845",
          image: "/images/gls/inabox25/03 GIAB Buttons/5 I Itepekimab/GIAB PF_I1.jpg",
          type: VIDEO_TYPE,
        },
        {
          id: 2,
          title: "ITEPEKIMAB SESSION",
          url: "https://launchpharm.box.com/s/ve55jhd3ihkkmoq1pi9kmgxyvfishb93",
          image: "/images/gls/inabox25/03 GIAB Buttons/5 I Itepekimab/GIAB PF_I2.jpg",
          type: LINK_TYPE,
        },
        {
          id: 3,
          title: "MISSION 33 OPENING",
          url: "https://playout.3qsdn.com/675275b5-0d91-4440-8584-32deadc52a8a",
          image: "/images/gls/inabox25/03 GIAB Buttons/5 I Itepekimab/GIAB PF_I3.jpg",
          type: VIDEO_TYPE,
        },
        {
          id: 4,
          title: "MISSION 33 CLOSING",
          url: "https://playout.3qsdn.com/c092fad4-6715-4320-9783-b420d60d6e3d",
          image: "/images/gls/inabox25/03 GIAB Buttons/5 I Itepekimab/GIAB PF_I4.jpg",
          type: VIDEO_TYPE,
        }
      ]
    }
  ]
};

const PHOTOS_INNER25 = {
  header: "/images/gls/inabox25/02 GIAB Header/Header Photos.jpg",
  content: [
    {
      id: 7,
      sectionTitle: "2025",
      path: "/gls/in-a-box/gls25/7/photos",
      slug: "photos",
      contents: [
        {
          id: 1,
          title: "GLSLIVE25 DAY 1",
          url: "/images/gls/inabox25/photos/day1",
          albumSlug: "gls-live-25-day-1",
          image: "/images/gls/inabox25/03 GIAB Buttons/6 Photos/GIAB PF_P1.jpg",
          type: GALLERY_TYPE,
        },
        {
          id: 2,
          title: "GLSLIVE25 DAY 2",
          url: "/images/gls/inabox25/photos/day2",
          image: "/images/gls/inabox25/03 GIAB Buttons/6 Photos/GIAB PF_P2.jpg",
          type: GALLERY_TYPE,
          albumSlug: "gls-live-25-day-2",
        },
        {
          id: 3,
          title: "GLSLIVE25 DAY 3",
          url: "https://launchpharm.box.com/s/lrhy8flqiflqyb35aouu32rqf46lsmsn",
          image: "/images/gls/inabox25/03 GIAB Buttons/6 Photos/GIAB PF_P3.jpg",
          type: GALLERY_TYPE,
          albumSlug: "gls-live-25-day-3",
        },
        {
          id: 4,
          title: "GLSLIVE25 AWARD NIGHT",
          url: "https://launchpharm.box.com/s/dpo0iwdmarwwpzp720ldrd4nmws45ba7",
          image: "/images/gls/inabox25/03 GIAB Buttons/6 Photos/GIAB PF_P7.jpg",
          type: GALLERY_TYPE,
          albumSlug: "gls-live-25-award-night",
        }
      ]
    }
  ]
};

export const IN_A_BOX_CONTENT = [
  {
    id: 0,
    sectionTitle: "2025",
    path: "/gls/in-a-box/gls25",
    slug: "gls25",
    contents: [
      {
        id: 0,
        title: "E-Magazine",
        url: "https://launchpharm.box.com/s/8k4690lpx0h4b4dwwa6ezs21pbdisa4t",
        image: "/images/gls/inabox25/01 GIAB Submenu/Sub Tile 1.jpg",
        type: LINK_TYPE,
      },
      {
        id: 2,
        title: "Franchise",
        url: "2",
        image: "/images/gls/inabox25/01 GIAB Submenu/Sub Tile 2.jpg",
        type: LINK_TYPE,
        innerContent: FRANCHISE_INNER25.content,
        innerHeader: FRANCHISE_INNER25.header,
      },
      {
        id: 3,
        title: "Dermatology",
        url: "3",
        image: "/images/gls/inabox25/01 GIAB Submenu/Sub Tile 3.jpg",
        type: LINK_TYPE,
        innerContent: DERMATOLOGY_INNER25.content,
        innerHeader: DERMATOLOGY_INNER25.header,
      },
      {
        id: 4,
        title: "Respiratory",
        url: "4",
        image: "/images/gls/inabox25/01 GIAB Submenu/Sub Tile 4.jpg",
        type: LINK_TYPE,
        innerContent: RESPIRATORY_INNER25.content,
        innerHeader: RESPIRATORY_INNER25.header,
      },
      {
        id: 5,
        title: "Rhino Gastro",
        url: "5",
        image: "/images/gls/inabox25/01 GIAB Submenu/Sub Tile 5.jpg",
        type: LINK_TYPE,
        innerContent: RHINO_GASTRO_INNER25.content,
        innerHeader: RHINO_GASTRO_INNER25.header,
      },
      {
        id: 6,
        title: "ITEPEKIMAB",
        url: "6",
        image: "/images/gls/inabox25/01 GIAB Submenu/Sub Tile 6.jpg",
        type: LINK_TYPE,
        innerContent: ITEPEKIMAB_INNER25.content,
        innerHeader: ITEPEKIMAB_INNER25.header,
      },
      {
        id: 7,
        title: "Photos",
        url: "7",
        image: "/images/gls/inabox25/01 GIAB Submenu/Sub Tile 7.jpg",
        type: LINK_TYPE,
        innerContent: PHOTOS_INNER25.content,
        innerHeader: PHOTOS_INNER25.header,
      },
    ],
  },
  {
    id: 1,
    sectionTitle: "2024",
    path: "/gls/in-a-box/gls24",
    slug: "gls24",
    contents: [
      {
        id: 0,
        title: "Update Q2",
        url: "0",
        image: "/images/gls/inabox/Summer_Update.jpg",
        type: LINK_TYPE,
        innerContent: Q2_INNER.content,
        innerHeader: Q2_INNER.header,
      },
      {                   
        id: 99,
        title: "",
        url: "#",
        image: "",
        type: LINK_TYPE,
        extraClass: "mobile-only",
      },
      {
        id: 98,
        title: "",
        url: "#",
        image: "",
        type: LINK_TYPE,
        extraClass: "mobile-md-only",
      },
      {
        id: 1,
        title: "E-Magazine",
        url: "https://launchpharm.box.com/s/6rxszzlwmwymcr22j9qagrtgoi7a0hlc",
        image: "/images/gls/inabox/Button_Sub_1_Tile_1.jpg",
        type: LINK_TYPE,
      },
      {
        id: 2,
        title: "Franchise",
        url: "2",
        image: "/images/gls/inabox/Button_Sub_1_Tile_2.jpg",
        type: LINK_TYPE,
        innerContent: FRANCHISE_INNER.content,
        innerHeader: FRANCHISE_INNER.header,
      },
      {
        id: 3,
        title: "Dermatology",
        url: "3",
        image: "/images/gls/inabox/Button_Sub_1_Tile_3.jpg",
        type: LINK_TYPE,
        innerContent: DERMATOLOGY_INNER.content,
        innerHeader: DERMATOLOGY_INNER.header,
      },
      {
        id: 4,
        title: "COPD",
        url: "4",
        image: "/images/gls/inabox/Button_Sub_1_Tile_4.jpg",
        type: LINK_TYPE,
        innerContent: COPD_INNER.content,
        innerHeader: COPD_INNER.header,
      },
      {
        id: 5,
        title: "Asthma",
        url: "5",
        image: "/images/gls/inabox/Button_Sub_1_Tile_5.jpg",
        type: LINK_TYPE,
        innerContent: ASTHMA_INNER.content,
        innerHeader: ASTHMA_INNER.header,
      },
      {
        id: 6,
        title: "CRSwNP EoE",
        url: "6",
        image: "/images/gls/inabox/Button_Sub_1_Tile_6.jpg",
        type: LINK_TYPE,
        innerContent: CRSWNP_INNER.content,
        innerHeader: CRSWNP_INNER.header,
      },
    ],
  },
];

export const MEDIA_HUB_TITLE_TILE = "/images/gls/25/P Sub 3.jpg";
export const IN_A_BOX_TITLE_TILE = "/images/gls/25/P Sub 2.jpg";
