// store/gls-photos/reducer.js
import { GLS_PHOTOS, API } from '../../../utils/constants';
import { mapImageData, mapImageFormats } from "../../../utils/mappings";

const STATE = {
  albums: [],
  currentAlbum: null,
  loading: false,
  error: null
};

// Helper function to map album data from the API response
const mapAlbums = (data) => {
  return data.map(album => {
    const { id, attributes } = album;
    const { title, slug, description, coverImage } = attributes;
    
    return {
      id,
      title,
      slug,
      description,
      coverImage: coverImage?.data ? 
        `${API?.URL}${coverImage.data.attributes.url}` : null
    };
  });
};

// Helper to map a single album with photos
const mapAlbumDetails = (data) => {
  if (!data.length) return null;
  
  const album = data[0];
  const { id, attributes } = album;
  const { title, description, photos } = attributes;
  
  return {
    id,
    title,
    description,
    photos: photos?.data ? photos.data.map(photo => ({
      id: photo.id,
      url: `${API?.URL}${photo.attributes.url}`,
      thumbnail: photo.attributes.formats?.thumbnail ? 
        `${API?.URL}${photo.attributes.formats.thumbnail.url}` : 
        `${API?.URL}${photo.attributes.url}`,
        large: photo.attributes.formats?.large ? 
        `${API?.URL}${photo.attributes.formats.large.url}` : 
        `${API?.URL}${photo.attributes.url}`,
      caption: photo.attributes.alternativeText || ''
    })) : []
  };
};

function glsPhotos(state = STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case GLS_PHOTOS.LOAD_ALBUMS:
      return { ...state, loading: true, error: null };
      
    case GLS_PHOTOS.LOAD_ALBUMS_SUCCESS:
      return { 
        ...state, 
        albums: mapAlbums(payload.data), 
        loading: false 
      };
      
    case GLS_PHOTOS.LOAD_ALBUMS_ERROR:
      return { ...state, loading: false, error: 'Failed to load albums' };
      
    case GLS_PHOTOS.LOAD_ALBUM_DETAILS:
      return { ...state, currentAlbum: null, loading: true, error: null };
      
    case GLS_PHOTOS.LOAD_ALBUM_DETAILS_SUCCESS:
      return { 
        ...state, 
        currentAlbum: mapAlbumDetails(payload.data), 
        loading: false 
      };
      
    case GLS_PHOTOS.LOAD_ALBUM_DETAILS_ERROR:
      return { ...state, loading: false, error: 'Failed to load album details' };
      
    default:
      return state;
  }
}

export default glsPhotos;