import {combineReducers} from '@reduxjs/toolkit';
import company from './company/reducer';
import loading from './loading/reducer';
import mediaHub from './media-hub/reducer';
import news from './news/reducer';
import tracking from "./tracking/reducer";
import user from "./user/reducer";
import whatsNew from './whatsnew/reducer';
import sticker from './sticker/reducer';
import events from './events/reducer';
import glsPhotos from './gls-photo/reducer';

const reducers = combineReducers({
    user,
    events,
    tracking,
    company,
    whatsNew,
    mediaHub,
    news,
    sticker,
    loading,
    glsPhotos
});

export default reducers;
